//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import Stickyfill from 'stickyfilljs'
//-----------------------------------------------------------------------------------------------------------------

export function noSamePageDestination() {
  var links = document.querySelectorAll('a[href]')
  var cbk = function(e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk)
  }
}

export function closeAlert() {
	var alertHgh = $('#alertBar').height();
	var headerHgh = $('header').height() - alertHgh;
	var windowHght = $(window).height() - headerHgh;

	$('header').css('top','-'+alertHgh+'px');
  $('html').removeClass('show-alert');
	$('#homeBanner').css('height', windowHght + 'px');
	return false;
}

export function fixDropdownHeight() {
 	var height1 = $('#dropVille').height();
	var height2 = $('#dropServices').height();
	var height3 = $('#dropDecouvrir').height();
	var height4 = $('#dropLoisirs').height();
	$('#dtDrop').css('height', Math.max(height1, height2, height3, height4) + 70);
	$("#dtDropZone").hoverIntent(
		function (){ $('html').addClass('show-desktopDrop'); },
		function (){ $('html').removeClass('show-desktopDrop show-menuVille show-menuServices show-menuLoisirs show-menuDecouvrir'); });
	$("#navVille").hoverIntent( function (){ $('html').removeClass('show-menuServices show-menuLoisirs show-menuDecouvrir').addClass('show-menuVille'); });
	$("#navServices").hoverIntent( function (){ $('html').removeClass('show-menuVille show-menuLoisirs show-menuDecouvrir').addClass('show-menuServices'); });
	$("#navDecouvrir").hoverIntent( function (){ $('html').removeClass('show-menuVille show-menuServices show-menuLoisirs').addClass('show-menuDecouvrir'); });
	$("#navLoisirs").hoverIntent( function (){ $('html').removeClass('show-menuVille show-menuServices show-menuDecouvrir').addClass('show-menuLoisirs'); });
}

export function initMainNavBar() {

	var navbartimeout, linkspace;
	$("#dtMainMenu").hoverIntent(showNavBar,hideNavBar,'li');

	function showNavBar() {
		clearTimeout(navbartimeout);
		linkspace = $('span', this);
		if ( $('#navbar').width() == 0 )
		{
			$('#navbar').css('left', (linkspace.position().left + linkspace.width()/2) + 'px');
			$('#navbar').stop(true, false).animate({width: linkspace.width() + 20 + 'px', left: linkspace.position().left - 10 +  'px'}, 500, 'easeOutCirc');
		}
		else $('#navbar').stop(true, false).animate({width: linkspace.width() + 20 + 'px', left: linkspace.position().left - 10 + 'px'}, 500, 'easeOutCirc');
	};
	function hideNavBar(){};

	$("#dtDropZone").hoverIntent(showNavBar2,hideNavBar2);
	function showNavBar2() {};
	function hideNavBar2() {
		navbartimeout = setTimeout( function() {
			$('#navbar').stop(true, false).animate({width: '0px', left: (linkspace.position().left + linkspace.width()/2) + 'px'}, 200, 'easeOutCirc');
		}, 300);
	};
}

// Ajoute un min-height a la section pour compenser l'aside.
export function sectionMinHeight() {

	var asideHeight = $('aside .sticky').height();
	$('section').css('min-height', (asideHeight) + 70 + 'px');
	$(window).on( 'resize', function() {
		asideHeight = $('aside .sticky').height();
		$('section').css('min-height', (asideHeight) + 70 + 'px');
	});
}

export function ocList() {
  $('.ocList > li > a').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.ocList li.open .ocListCt').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.ocList li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.ocListCt', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}

export function initBannerHeight() {
	var windowHght;
	var alertHgh = $('#alertBar').height();
	if(typeof alertHgh == 'undefined'){ alertHgh = 0 }
	var headerHgh = $('header').height() - alertHgh;

	if ( $('html').hasClass('show-alert') ) windowHght = $(window).height() - (headerHgh + alertHgh);
	else windowHght = $(window).height() - headerHgh;
	$('#homeBanner').css('height', windowHght + 'px');

	$(window).resize( function() {
		if ( $('html').hasClass('show-alert') ) windowHght = $(window).height() - (headerHgh + alertHgh);
		else windowHght = $(window).height() - headerHgh;
		$('#homeBanner').css('height', windowHght + 'px');
	});
}

export function scrollToBlock (depart, destination, duration, ajustement) {
	// Paramètres optionnels
	duration   = (typeof duration   === 'undefined') ? 600 : duration
	ajustement = (typeof ajustement === 'undefined') ? 0   : ajustement

	// Défilement au clique
	$(depart).on( 'click', function(e) {
	e.preventDefault();
    //if($(window).width() <= 1024) ajustement = 0
	//	$.fn.BLK_smoothScroll.destroy()
    $('html, body').animate({
      scrollTop: ( destination != '' ? $(destination).offset().top + ajustement : 0 )
    }, duration, 'easeInOutExpo')
  })
}

export function initSelect() {
	$('.chosen-select').chosen({ disable_search: true });
	$('.chosen-results').addClass('innerscroll');
}

export function eventsLoaded(data) {
  if (!data['hasMorePages']) $('#eventsMoreBtn a').hide();
  var pageSelector = $('.eventsPage' + data['currentPage']);
  //console.log('.eventsPage' + data['currentPage']);
  //console.log(pageSelector);
  //setTimeout( function() { $('html, body').animate({ scrollTop: pageSelector.offset().top - 100}, 600, 'easeInOutExpo', function() {});}, 250);
}

export function recaptcha() {
	grecaptcha.ready(function() {
		grecaptcha.execute('6LeKr9cfAAAAANADb16HUHZrHDeG2ItONsy8NC08', {action: 'homepage'}).then( function(token) {
			$('#captcha_token_v3').val(token);
		});
	});
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

export function fallbackCopyTextToClipboard(text) {

  var textArea = document.createElement("textarea")
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try { var successful = document.execCommand('copy') } catch (err) {}

  document.body.removeChild(textArea)
  $(document).scrollTop(0)
}

export function shareUrl() {
  $('.share.rubric').on('click', function(e) {
    ajustDataUrls(window.location.href + '/rubriques/' + e.currentTarget.dataset.slug)
  })

  $('.share.page').on('click', function(e) {
    ajustDataUrls(window.location.href)
  })
}

function ajustDataUrls(url) {
  //pour le partage par courriel
  $('#emailShareBtn').attr('href', 'mailto:?Subject=Ville de Sainte-Marguerite-du-Lac-Masson&body=' + url)
  //pour le partage par facebook ou twitter
  $('#facebookShareBtn').attr('data-url', url)
  $('#twitterShareBtn').attr('data-url', url)
  //pour copier le lien
  $('#copyShareBtn').unbind().click( function() {
    copyTextToClipboard(url)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })
}
