//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Validation du formulaire de l'infolettre
export function infolettreForm(form, submit) {
	
	var m = getMessages();
	var rules = 	{ courriel: { required: true, email: true }};
	var messages = 	{ courriel: { required: m.required, email: m.email }};
	formValidation(form, submit, rules, messages);
}

// Validation du formulaire du popup l'infolettre
export function popupInfolettre(form, submit) {
	
	var m = getMessages();
	var rules = 	{ courriel: { required: true, email: true }};
	var messages = 	{ courriel: { required: m.required, email: m.email }};
	formValidation(form, submit, rules, messages);
	
	$('.closePopup').click( function() {
		//$.cookie('overlayInfolletre', 'yes', { expires: 7, path: '/' });
		$('html').removeClass('show-infolettrePopup');
		return false;
	});
}

// Validation du formulaire de la page contact
export function contactForm(form, submit) {
	
	formatPhone('#telephone');
	
	var m = getMessages();
	
	var rules = 	{ 
		nom: 		{ required: true },
		courriel: 	{ required: true, email: true },
		telephone: 	{ required: true, minlength: 14 },
		message: 	{ required: true }
	};
	
	var messages = 	{ 
		nom: 		{ required: m.required },
		courriel: 	{ required: m.required, email: m.email },
		telephone: 	{ required: m.required, minlength: m.phone },
		message: 	{ required: m.message }
	};
	
	formValidation(form, submit, rules, messages);
}

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
	return {
		required: 'Ce champ est obligatoire.',
		select: 'Veuillez sélectionner une option.',
		email: 'Veuillez fournir une adresse électronique valide.',
		phone: 'Veuillez fournir un numéro de téléphone valide.',
		postale: 'Veuillez fournir un code postal valide.',
		date: 'Veuillez fournir une date valide.',
		accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
		file: 'Veuillez lier le fichier adéquat pour ce champ.',
		message: 'Veuillez écrire votre demande ou commentaire.'
	}
}

// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}

// Format du code postal
export function formatCodePostal(element) {
  $(element).mask('000 000')
}

// Format de la date
export function formatDate(element) {
  $(element).mask('00/00/00')
}


// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages) {
  $.validator.setDefaults({ ignore: [] })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    }
  })
  $(submit).click(function() {

	// Si recaptcha est présent on le valide avant d’envoyer et tester le formulaire
	if ( $("[name=recaptcha_token_v3]",$(form) ) == null ) {
		$(form).submit()
	} else {
		grecaptcha.ready(function() {
			grecaptcha.execute('6LeKr9cfAAAAANADb16HUHZrHDeG2ItONsy8NC08', {action: 'homepage'}).then( function(token) {
			$('#captcha_token_v3').val(token);
			$(form).submit()
			});
		});
	}

	return false;
  })
}
