//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay, initCloseSubMenu } from './../classes/overlay.js'
//-----------------------------------------------------------------------------------------------------------------


// Fonction gérant le menu mobile
export function overlayMenuMobile() {
  const overlayMenuMobile = new Overlay('#sideMenuBtn', 'show-sidemenu', '#overlay-mobileMenu .closeOverlay')

	$('#sideMenu .sub > a').click( function() {
		var clickedli = $(this).parent();
		var clickedul = $(this).parent().parent();
		var toggling = clickedli.hasClass('open');
		$('li.open div', clickedul).animate( {height: 'hide'}, {duration: 400, specialEasing: { height: 'easeOutExpo' },
				complete: function() {
				  $(this).css('height', 'auto');
				}});
		$('li.open', clickedul).removeClass('open');
		if ( !toggling ) {
			setTimeout(function() { clickedli.addClass('open'); }, 0);
			$('> div', clickedli).stop(true, false).animate( {height: 'show'}, { duration: 400, specialEasing: { height: 'easeOutExpo' },
				complete: function() {
				  $(this).css('height', 'auto');
				}});
		}
		return false;
	});
}

export function searchDrop() {

	$('#searchBtn').click( function() {
		$('html').addClass('show-search').removeClass('show-sidemenu');
		$('.hamburger').removeClass('is-active');
		$('#searchInput').focus();
		return false;
	});
	
	$('#closeSearch').click( function() {
		$('html').removeClass('show-search');
		return false;
	});
	
	$('.closeOverlay').click( function() {
      $('html').removeClass('show-search');
	  return false;
    });
}

export function shareModal() {

	$('.share').click( function() {
		$('html').addClass('show-shareModal');
		return false;
	});
	
	$('#shareCloseBtn').click( function() {
		$('html').removeClass('show-shareModal');
		return false;
	});
	
	$('.closeOverlay').click( function() {
      $('html').removeClass('show-shareModal');
	  return false;
    });
}

export function sideRep() {

	$('#repList .ocListCt a ').click( function() {
		$('html').addClass('show-sideRep');
		return false;
	});
	
	$('#closeRep').click( function() {
		$('html').removeClass('show-sideRep');
		return false;
	});
	
	$('.closeOverlay').click( function() {
		$('html').removeClass('show-sideRep');
		return false;
    });
}