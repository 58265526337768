//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}

export function homeCycle() {

  $('#homeCycle').on('init', function(event, slick){
    $('#cyclePager .nextSlide').html(slick.slideCount)
  })

  $('#homeCycle').slick({
    zindex: 10,
    arrows: false,
    dots: false,
    easing: 'easeInOutExpo',
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    fade: true,
    autoplaySpeed: 5000,
    speed: 1000
  })

  $('#homeCycle').on('afterChange', function(event, slick, currentSlide, nextSlide){
    $('#cyclePager .spacer').toggleClass('reset');
    $('#cyclePager .currentSlide').html(currentSlide + 1);
  })


  $('#homeCycle').on('beforeChange', function(event, slick, currentSlide, nextSlide){

  })

  equalSlidesMobile()
  $(window).on( 'resize', function() {
    equalSlidesMobile()
  })

  function equalSlidesMobile() {
    $('#homeBanner').css('--content-height', '0')
    let maxHeight = -1
    $('.js-equal-height').each(function() {
      maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height()
    })
    $('#homeBanner').css('--content-height', maxHeight+'px')
  }

}

export function homeNewsCycle() {
  $('#homeNewsCycle').slick({
    appendDots: '#homeNewsCycle',
    zindex: 10,
    arrows: false,
    dots: true,
    easing: 'easeInOutExpo',
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    fade: true,
    autoplaySpeed: 5000,
    speed: 1000
  })
}

export function homeMobileNewsCycle() {
  $('#homeMobileNewsCycle').slick({
    appendDots: '#homeMobileNewsCycle',
    zindex: 10,
    arrows: false,
    dots: true,
    easing: 'easeInOutExpo',
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    fade: true,
    autoplaySpeed: 5000,
    speed: 1000
  })

  $('#homeMobileNewsCycle').on('setPosition', function (event, slick) {
    $('#homeMobileNewsCycle .slick-slide').css('height', 'auto')
    slick.$slides.css('height', slick.$slideTrack.height() + 'px')
  })
}
