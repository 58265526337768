//------------------------\\
//---- Classe Overlay ----\\
//------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Classe permettant d'ajouter une classe au HTML pouvant ouvrir un overlay
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------


export class Loader {

  // Initialisation du chargement
  start() {
    this.showBar()
    this.theInterval = setInterval( () => { this.intervalChecker() }, 1)
    this.setLoadList()
    this.loadingCheck()
  }


  // Réinitialise toutes les variables du loader
  reset() {
    this.theInterval = null
    this.targetContainer = false
    this.callbackComplete = null
    this.imgLoaded = 0
    this.imgToLoad = 0
    this.loadList = null
    this.setLoadingBar()
  }


  // Montre la barre de chargement
  showBar() { $('#loaderbar').fadeIn(100) }


  // Cache la barre de chargement
  hideBar() { $('#loaderbar').fadeOut() }


  // Appelle ces deux fonctions à chaque intervalle
  intervalChecker() {
    this.setLoadingBar()
    this.checkForCallback()
  }


  // Actualise la valeur du targetContainer
  setContainer(domElement) {
    this.targetContainer = domElement
  }


  // Défini le callback lors de l'appel de cette fonction
  setCompleteCallback(callback) {
    this.callbackComplete = callback
  }


  // Actualise le visuel de la barre de chargement
  setLoadingBar() {
    var percent
    if (this.imgToLoad > 0)
      percent = this.imgLoaded / this.imgToLoad * 100
    else
      percent = 0

    $('#loaderbar').css('width', Math.ceil(percent) + '%')
  }


  // Détermine une liste d'éléments à charger
  setLoadList() {
    this.loadList = null
    this.imgToLoad = 0

    // Va chercher le nom des éléments à charger
    var searchlist
    if (this.targetContainer)
      searchlist = this.setContainer + 'div, ' + this.setContainer + ' img'
    else
      searchlist = 'div, img'

    // Création de la liste d'éléments ayant une image à charger
    this.loadList = $(searchlist).filter( function() {
      var attr = $(this).attr('src')
      if ($(this).css('background-image').indexOf('url') > -1)
        return true
      if (typeof attr !== typeof undefined && attr !== false)
        return true
    })

    // Incrémente le nombre d'image à charger
    this.loadList.each( () => { this.imgToLoad++ })
  }


  // Incrémente la variable du nombre d'image chargé lorsqu'elles le sont
  loadingCheck() {
    var tUrl = new Array()

    this.loadList.each(function() {
      if (this.tagName.toLowerCase() != 'img') {
        var regular = new RegExp(/url\(([^()]*)\)/)
        var bi = $(this).css('background-image').match(regular).pop()
        var regReplace = new RegExp('[\'"]', 'gi')
        bi = bi.replace(regReplace, '') // bug IE, Safari, EDGE, Chrome
        if (tUrl.indexOf(bi) < 0)
          tUrl.push(bi)
      } else {
        var imgsrc = $(this).attr('src')
        if (tUrl.indexOf(imgsrc) < 0)
          tUrl.push(imgsrc)
      }
    })

    this.imgToLoad = tUrl.length

    for (var n = 0; n < tUrl.length; n++) {
      $('<img/>').on('load', (e) => {
        $(e.currentTarget).remove()
        this.imgLoaded++
      }).on('error', (e) => {
        $(e.currentTarget).remove()
        this.imgLoaded++
      }).attr('src', tUrl[n])
    }
  }


  // Vérifie si le chargement est terminé
  checkForCallback() {
    if (this.imgLoaded >= this.imgToLoad) {
      clearTimeout(this.theInterval)
      this.hideBar()
      this.theInterval = null
      for (var i = 0; i < this.callbackComplete.length; i++) {
        this.callbackComplete[i]()
      }

      // Création de l'événement loadComplete
      $(window).trigger('loadComplete')
    }
  }
}
