//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js'
import { noSamePageDestination, fixDropdownHeight, initMainNavBar, closeAlert, sectionMinHeight, ocList, initBannerHeight, scrollToBlock, initSelect, initContentHeight, recaptcha, shareUrl } from './functions/functions.js'
import { overlayMenuMobile, searchDrop, shareModal, sideRep } from './functions/overlay.js'
import { homeCycle, homeNewsCycle, homeMobileNewsCycle, destroySlick } from './functions/slicks.js'
import { infolettreForm, popupInfolettre, contactForm } from './functions/forms.js'
import { dynamicPackage } from './functions/dynamic.js'
//-----------------------------------------------------------------------------------------------------------------

window.recaptcha = recaptcha

export class View {
	static init() {
		svg4everybody()
		$.fn.BLK_scrollfire();
		noSamePageDestination();
		fixDropdownHeight();
		initMainNavBar();
		overlayMenuMobile();
		searchDrop();
		shareModal();
    shareUrl();
		popupInfolettre('#popupInfolettreForm', '#popupInfolettreFormSubmit');
		infolettreForm('#infolettreForm', '#infolettreFormSubmit');
	}

	static leave() {

	}
}

export class Generic extends View {
  static initGeneric() {
	this.init();
	sectionMinHeight();
	ocList();
	initSelect();
	initContentHeight();
		dynamicPackage();
  }

  static leaveGeneric() {
	this.leave();
  }
}

export class Rubrique extends View {
  static initRubrique() {
	this.init();
	sectionMinHeight();
	ocList();
	initSelect();
	dynamicPackage();
  }

  static leaveRubrique() {
	this.leave();
  }
}

export class Document extends View {
  static initDocument() {
	this.init();
	ocList();
	initSelect();
	sectionMinHeight();
	dynamicPackage();
  }

  static leaveDocument() {
	this.leave();
  }
}

export class Detail extends View {
  static initDetail() {
	this.init();
	sectionMinHeight();
	dynamicPackage();
  }

  static leaveDetail() {
	this.leave();
  }
}

export class Home extends View {
  static initHome() {
	this.init();
	initBannerHeight();
	homeCycle();
	homeMobileNewsCycle();
	homeNewsCycle();
	scrollToBlock ('#scrollDown', '#homeNews', 700, 100);
  }

  static leaveHome() {
	this.leave();
	//destroySlick('#homeCycle');
	//destroySlick('#homeNewsCycle');
  }
}

export class Repertoire extends View {
  static initRepertoire() {
	this.init();
	sectionMinHeight();
	ocList();
	sideRep();
  }

  static leaveRepertoire() {
	this.leave();
  }
}

export class Contact extends View {
  static initContact() {
	this.init();
	ocList();
	contactForm('#contactForm', '#contactFormSubmit');
	scrollToBlock ('#scrolldown', '#coordonnees', 700, -50);
	initSelect();
  }

  static leaveContact() {
	this.leave();
  }
}

export class Recherche extends View {
  static initRecherche() {
	this.init();
	initSelect()
  }

  static leaveRecherche() {
	this.leave();
  }
}
