//-----------------------------\\
//--- Transition avec barba ---\\
//-----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// C'est ici que les transition entre les page sont gérées, on y retrouve les différentes views spécifiques à
// chaque page. Tout commence avec la fonction doOnce.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'
import {
  setView
} from './views.js'
import {
  Loader
} from './classes/loader.js'
//-----------------------------------------------------------------------------------------------------------------


// Cette fonction Gère la transition entre les page
export function initPageTransition() {
  Barba.Pjax.cacheEnabled = false
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper'
  Barba.Pjax.Dom.containerClass = 'transition-container'
  Barba.Pjax.ignoreClassLink = 'no-transition'
  Barba.Dispatcher.on('initStateChange', function(currentStatus) {
    if( typeof fbq === 'function'  ) fbq('track', 'PageView') // Facebook pixel
  })
  setTransition()
  setView()
  Barba.Pjax.start()
}


// Cette fonctions exécute la transition entre les pages
function setTransition() {
  var FadeTransition = Barba.BaseTransition.extend({
    start: function () {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this))
    },
    fadeOut: function () {
      $('html').removeClass('show-desktopDrop show-menuLoisirs show-menuDecouvrir show-menuServices show-menuVille');
      setTimeout(function () {
        $('html').removeClass('show-search show-sidemenu noscroll');
      }, 10);
      return $('html').addClass('transition').delay(10).promise()
    },
    fadeIn: function () {
      if (!window.location.hash) {
        $('html, body').animate({
          scrollTop: $('html').offset().top
        }, 10);
      }
      $('html').addClass('closeTransition');
      setTimeout(function () {
        $('html').removeClass('closeTransition transition');
      }, 10);
      $.fn.BLK_scrollfire.init();
      this.done();
    }
  })

  Barba.Pjax.getTransition = function () {
    return FadeTransition
  }
}
