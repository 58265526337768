//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'

import {
  View, Home, Generic, Rubrique, Document, Detail, Repertoire, Contact, Recherche
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'stdView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init();  },
    onLeave:          function() { View.leave(); },
    onLeaveCompleted: function() {              }
  }).init();

  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter: function() {  },
    onEnterCompleted: function() { Home.initHome(); },
    onLeave: function() 		 { Home.leaveHome(); },
    onLeaveCompleted: function() {}
  }).init();
  
  // Page généric __________
  Barba.BaseView.extend({
    namespace: 'genericView',
    onEnter: function() { },
    onEnterCompleted: function() { Generic.initGeneric(); },
    onLeave: function() 		 { Generic.leaveGeneric(); },
    onLeaveCompleted: function() {}
  }).init();

  // Page générique rubrique __________
  Barba.BaseView.extend({
    namespace: 'rubriqueView',
    onEnter: function() { },
    onEnterCompleted: function() { Rubrique.initRubrique(); },
    onLeave: function() 		 { Rubrique.leaveRubrique(); },
    onLeaveCompleted: function() {}
  }).init();

  // Page générique documents __________
  Barba.BaseView.extend({
    namespace: 'documentView',
    onEnter: function() { },
    onEnterCompleted: function() { Document.initDocument(); },
    onLeave: function() 		 { Document.leaveDocument(); },
    onLeaveCompleted: function() {}
  }).init();
  
  // Page détail __________
  Barba.BaseView.extend({
    namespace: 'detailView',
    onEnter: function() {},
    onEnterCompleted: function() { Detail.initDetail(); },
    onLeave: function() 		 { Detail.leaveDetail(); },
    onLeaveCompleted: function() {}
  }).init();
  
  // Page Répertoire __________
  Barba.BaseView.extend({
    namespace: 'repertoireView',
    onEnter: function() {},
    onEnterCompleted: function() { Repertoire.initRepertoire(); },
    onLeave: function() 		 { Repertoire.leaveRepertoire(); },
    onLeaveCompleted: function() {}
  }).init();
  
  // Page contact __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter: function() {},
    onEnterCompleted: function() { Contact.initContact() },
    onLeave: function() 		 { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()

  // Page recherche __________
  Barba.BaseView.extend({
    namespace: 'rechercheView',
    onEnter: function() {},
    onEnterCompleted: function() { Recherche.initRecherche() },
    onLeave: function() 		 { Recherche.leaveRecherche() },
    onLeaveCompleted: function() {}
  }).init()
}
