//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation des nodes modules __________
import $ from 'jquery'
window.jQuery = $
import 'jquery.easing'
import 'jquery-validation'
import 'slick-carousel'
// import 'slick-carousel/slick/slick-theme.scss'
// import 'slick-carousel/slick/slick.scss'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'
import 'jquery-mask-plugin'
import 'jquery-hoverintent'
import 'chosen-js'

// Importation des scripts __________
import './plugins/smoothScroll.js'
import './plugins/scrollfire.js'
import './transition.js'
import './global.js'

// Importation pour les scripts un peu plus bas __________
import { eventsLoaded, closeAlert } from './functions/functions.js'
import { Loader } from './classes/loader.js'
import { initPageTransition } from './transition.js'
window.eventsLoaded = eventsLoaded;
window.closeAlert = closeAlert;

// Démarrage des scripts __________
$(document).ready(function() {
  initPageTransition();
  setTimeout(function() {
    $('html').removeClass('chargement')
    //$.fn.BLK_scrollfire.init();
  }, 600)
  $(document).scrollTop(0);
  

  $('body').keydown( function(e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey  && keycode == 13) || (e.metaKey  && keycode == 13)) {
      location = '/administration'
      return false
    }
  })

})
